.blogFormContainer {
  margin: 0 auto;
  max-width: 675px;
  width: 100%;

  input[type="text"] {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    color: var(--font-color);
    font-family: var(--default-font);
    font-weight: 700;
    background: var(--bg-color);
    transition: color 0.5s ease, background 0.5s ease;
    text-overflow: ellipsis;
  }

  .title {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    overflow: auto;
  }

  .subtitle {
    font-size: 1.17em;
    margin-block-end: 1em;
  }

  .thumbnail {
    color: var(--primary-color) !important;
    margin-block-end: 1em;
    &:not(:placeholder-shown) {
      text-decoration: underline;
    }
    font-size: 1em;
  }

  .markdown {
    cursor: text;
    display: inline;
    width: 100%;
    background: var(--bg-color);
    white-space: pre-line;
    outline: none;
    overflow-x: hidden;
    overflow-wrap: break-word;

    font-size: 1em;
    color: var(--font-color);
    font-family: var(--default-font);
    background: var(--bg-color);
    transition: color 0.5s ease, background 0.5s ease;

    &:empty:before {
      content: attr(data-placeholder);
      color: #757575;
    }
  }

  .tagContainer {
    margin: 2em 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .tag {
      background: var(--font-color);
      transition: background 0.5s ease, color 0.5s ease;
      color: var(--bg-color);
      font-size: 14px;
      padding: 4px 20px;
      border-radius: 100vh;
      cursor: pointer;
      outline: none;
    }
    .addTag {
      background: var(--font-color);
      transition: background 0.5s ease, color 0.5s ease;
      color: var(--bg-color);
      font-size: 14px;
      padding: 4px 10px;
      text-align: center;
      border-radius: 100vh;
      cursor: pointer;
    }
  }
}
