.projectShowcaseContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
  // margin: 0 -4rem;
}

@media only screen and (max-width: 800px) {
  .projectShowcaseContainer {
    gap: 18px;
    margin: 0 0;
  }
}
