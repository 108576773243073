.blogEditorContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  overflow: hidden;
  box-sizing: border-box;
  // width: 95%;
  height: 90vh;
  // margin: 0 auto;
  padding-bottom: 25px;
  transition: border 0.5s ease;

  .overflowContainer {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
