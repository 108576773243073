.nav-logo {
  // disable touch on ios
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    z-index: 1;
    position: relative;
    width: 77.5px;
    height: 77.5px;
    transform: translate(1px, 0);
    // removed button signal on page load
    // animation: buttonSignal 0.4s 5 ease;
    g {
      fill: var(--font-color);
      transition: fill 0.5s ease;
    }
    transition: filter 0.5s ease;
    &:hover {
      filter: drop-shadow(0 0 2px var(--font-color));
    }
  }

  // SKY
  .sky {
    position: absolute;
    width: 90px;
    height: 90px;
    transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    // SUN
    .sun {
      filter: drop-shadow(0 2px 2px #fce570);
      position: absolute;
      top: 17.5%;
      left: -7.5%;
      transition: all 0.5s ease;
      .outer {
        position: absolute;
        top: -30px;
        background: #fce570;
        width: 27.5px;
        height: 27.5px;
        border-radius: 1px;
        &:before,
        &:after {
          position: absolute;
          content: "";
          height: 27.5px;
          width: 27.5px;
          background: #fce570;
          border-radius: 1px;
        }
        &:before {
          transform: rotate(30deg);
        }
        &:after {
          transform: rotate(60deg);
        }
      }
    }

    // MOON
    .moon {
      filter: drop-shadow(0 2px 2px #ecf6ff);
      position: absolute;
      top: calc(20% - 30px);
      left: -7.5%;
      background: #ecf6ff;
      width: 27.5px;
      height: 27.5px;
      border-radius: 100%;
      transition: all 0.5s ease;
      &:before {
        content: "";
        position: absolute;
        top: 17.5%;
        left: 20%;
        width: 7.5px;
        height: 7.5px;
        background: #d9e9f3;
        border-radius: 100%;
      }
      &:after {
        content: "";
        position: absolute;
        top: 40%;
        left: 50%;
        width: 5px;
        height: 5px;
        background: #d9e9f3;
        border-radius: 100%;
      }
    }

    // CLOUDS
    .cloud {
      position: absolute;
      opacity: 0.85;
      filter: url(#distort) drop-shadow(0 2px 2px lightgray);
      .rect {
        width: 30px;
        height: 10px;
        background: white;
        border-radius: 100px;
        transition: background 0.5s ease;
      }
      .circle {
        border-radius: 100%;
        background: white;
        position: absolute;
        transition: background 0.5s ease;
      }
      .circle-lg {
        width: 10px;
        height: 10px;
        top: -55%;
        left: 5%;
      }
      .circle-sm {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        left: 35%;
        top: -50%;
      }
    }
    .cloud1 {
      left: -35%;
      top: 5%;
      animation: swingCloud1 10s infinite ease;
    }
    .cloud2 {
      left: 20%;
      top: -2.5%;
      animation: swingCloud2 10s infinite ease;
    }
  }
}

.nav-logo[data-theme="light"] {
  .sun {
    opacity: 0.9;
    animation: moveSunIn 0.5s ease;
    .outer {
      animation: rotateSun 8s infinite linear;
    }
  }
  .moon {
    opacity: 0;
    animation: moveMoonOut 0.5s ease;
  }
}

.nav-logo[data-theme="dark"] {
  .sun {
    opacity: 0;
    animation: moveSunOut 0.5s ease;
  }
  .moon {
    opacity: 0.9;
    animation: moveMoonIn 0.5s ease;
  }
}

@keyframes buttonSignal {
  50% {
    transform: scale(1.1);
  }
}

@keyframes moveSunIn {
  0% {
    // transform: translateY(40px) translateX(-30px) scale(0);
    transform: translateY(40px) translateX(-30px) rotate(-90deg) scale(0);
  }
}
@keyframes moveSunOut {
  100% {
    transform: translateY(40px) translateX(60px) rotate(90deg) scale(0);
  }
}
@keyframes moveMoonIn {
  0% {
    transform: rotate(-10deg) translateY(60px) translateX(-60px) scale(0);
  }
}
@keyframes moveMoonOut {
  100% {
    transform: rotate(10deg) translateY(60px) translateX(60px) scale(0);
  }
}
@keyframes rotateSun {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes swingCloud1 {
  50% {
    transform: translateX(15px);
  }
}
@keyframes swingCloud2 {
  50% {
    transform: translateX(-15px);
  }
}

@media only screen and (max-width: 800px) {
  .nav-logo {
    margin-left: 1rem;
    svg:hover {
      filter: none;
    }
  }
}
