.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--bg-color);
  transition: background 0.5s ease;
  h1,
  h3 {
    color: var(--font-color);
    transition: color 0.5s ease;
    text-align: center;
  }
  h1 {
    margin-top: 1px;
    margin-bottom: -14px;
  }
  button {
    background: var(--font-color);
    color: var(--bg-color);
    border: none;
    border-radius: 100px;
    padding: 6px 25px;
    font-size: 16px;
    font-weight: 700;
    transition: color 0.5s ease, background 0.5s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.1s ease;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    h1 {
      font-size: 23px;
    }
    h3 {
      margin-right: 25px;
      margin-left: 25px;
      font-size: 16px;
    }
    button {
      margin-top: -5px;
      font-size: 14px;
    }
  }
}
