@import url("https://fonts.googleapis.com/css?family=Poppins:400,700");
@import url('https://fonts.googleapis.com/css?family=Fraunces:400,700');

:root {
  --primary-color: #0583d2;
  --secondary-color: #b8e3ff;
  --menu-bg-color: #e8e8e8;
  --menu-font-color: #191919;
  --font-color: #202020;
  --bg-color: #f2f2f2;
  --shadow-color: #000;
  --default-font: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
[data-theme='light'] {
  transition: all .5s ease;
}
[data-theme='dark'] {
  --primary-color: #b8e3ff;
  --secondary-color: #0583d2;
  --menu-bg-color: #191919;
  --menu-font-color: #e8e8e8;
  --font-color: #f2f2f2;
  --bg-color: #202020;
  transition: all .5s ease;
}