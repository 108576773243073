.blogListElementOptionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: absolute;
  z-index: 1;
  margin-left: -10px;
  width: calc(100% + 20px);
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  &:hover {
    opacity: 1;
    backdrop-filter: blur(5px);
  }
  .option {
    background: var(--bg-color);
    width: 50px;
    height: 50px;
    border: 2px solid var(--font-color);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon {
      font-size: 20px;
      color: var(--font-color);
    }
    transition: transform 0.1s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
}
