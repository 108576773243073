.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;

  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  &.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 4px solid rgba(255, 255, 255, 0);
    border-bottom: 4px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
    &:hover,
    &:active {
      border-top: 4px solid rgba(0, 0, 0, 0.5);
      border-bottom: 4px solid rgba(0, 0, 0, 0.5);
    }
  }

  &.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 4px solid rgba(255, 255, 255, 0);
    border-right: 4px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
  &:hover,
  &:active {
    border-left: 4px solid rgba(0, 0, 0, 0.5);
    border-right: 4px solid rgba(0, 0, 0, 0.5);
  }

  // &.disabled {
  //   cursor: not-allowed;
  //   &:hover {
  //     border-color: transparent;
  //   }
  // }
}

[data-theme="dark"] .Resizer {
  background: #fff;
  opacity: 0.5;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;

  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.horizontal {
    border-top: 4px solid rgba(255, 255, 255, 0);
    border-bottom: 4px solid rgba(255, 255, 255, 0);
    &:hover,
    &:active {
      border-top: 4px solid rgba(0, 0, 0, 0.5);
      border-bottom: 4px solid rgba(0, 0, 0, 0.5);
    }
  }

  &.vertical {
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    &:hover,
    &:active {
      border-left: 4px solid rgba(255, 255, 255, 0.5);
      border-right: 4px solid rgba(255, 255, 255, 0.5);
    }
  }
}
