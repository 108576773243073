.editBlogContainer {
  width: 95%;
  margin: 0 auto;
  .editBlogId {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    text-align: center;
    color: var(--font-color);
    transition: color 0.5s ease;
  }
  .editBlogMenu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .backContainer {
      display: flex;
      align-items: center;
      cursor: pointer;
      .backIcon {
        color: var(--font-color);
        transition: color 0.5s ease;
        font-size: 20px;
      }
      .backText {
        text-decoration: underline solid transparent;
        margin-left: 10px;
        color: var(--font-color);
        transition: color 0.5s ease, text-decoration 0.5s ease;
        &:hover {
          text-decoration: underline solid var(--font-color);
        }
      }
    }
    .saveContainer {
      display: flex;
      align-items: center;
      cursor: pointer;
      .saveIcon {
        color: var(--font-color);
        transition: color 0.5s ease;
        font-size: 20px;
      }
      .saveText {
        text-decoration: underline solid transparent;
        margin-right: 10px;
        color: var(--font-color);
        transition: color 0.5s ease, text-decoration 0.5s ease;
        &:hover {
          text-decoration: underline solid var(--font-color);
        }
      }
    }
  }
}
