.container {
  width: 100vw;
  height: 100vh;
  background: var(--bg-color);
  transition: background 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  .loginContainer {
    transform: translateY(-10%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      color: var(--font-color);
      margin-top: 8px;
      margin-bottom: 10px;
      font-size: 36px;
      transition: color 0.5s ease;
    }
    .loginMessage {
      margin-top: 0;
      font-weight: 700;
      width: 311px;
      text-align: center;
    }
    .formContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      label {
        color: var(--font-color);
        align-self: baseline;
        font-size: 16px;
        margin-bottom: 8px;
        transition: color 0.5s ease;
      }
      input[type="text"],
      input[type="password"] {
        margin-bottom: 16px;
        height: 30px;
        width: 300px;
        font-family: var(--default-font);
        font-size: 16px;
        border: 2px solid var(--font-color);
        border-radius: 5px;
        padding-left: 5px;
        transition: transform 0.1s ease, border 0.5s ease, background 0.5s ease;
        &:focus {
          outline: none;
          transform: scale(1.025);
        }
      }
      input[type="submit"] {
        cursor: pointer;
        margin-top: 12px;
        height: 36px;
        width: 200px;
        background: var(--font-color);
        border: none;
        font-family: var(--default-font);
        font-weight: 700;
        font-size: 16px;
        color: var(--bg-color);
        border-radius: 100vh;
        transition: transform 0.1s ease, background 0.5s ease, color 0.5s ease;
        &:hover {
          transform: scale(1.069);
        }
      }
    }
    .login {
      text-decoration: underline solid transparent;
      text-decoration-thickness: 2px;
      margin-top: 20px;
      font-weight: 700;
      color: var(--primary-color);
      position: relative;
      transition: text-decoration 0.5s ease, color 0.5s ease;
      &:hover {
        text-decoration: underline solid var(--primary-color);
        text-decoration-thickness: 2px;
      }
    }
  }
}

[data-theme="dark"] input {
  background: var(--font-color);
}

@media screen and (max-width: 800px) {
  .container {
    .loginContainer {
      h1 {
        font-size: 30px;
      }
      .formContainer {
        label {
          font-size: 15px;
        }
        input {
          font-size: 15px;
        }
      }
    }
  }
}
