.blogsContainer {
  h1 {
    text-align: center;
    color: var(--font-color);
    transition: color 0.5s ease;
  }
  .blogsListContainer {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    margin: 0 auto;
    max-width: 800px;

    .createBlogButton {
      width: 50px;
      height: 50px;
      border: 2px solid var(--font-color);
      border-radius: 10px;
      transition: border 0.5s ease, transform 0.1s ease;
      margin: 2.5rem 0;
      &:hover {
        transform: scale(1.1);
      }
      display: flex;
      align-items: center;
      justify-content: center;
      .createBlogIcon {
        text-decoration: none;
        color: var(--font-color);
        font-size: 26px;
        transition: color 0.5s ease;
      }
    }

    .blogsLoading {
      color: var(--font-color);
      transition: color 0.5s ease;
      text-align: center;
      margin: 18vh 0;
    }
  }
}

.temporaryLoader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 26vh auto;
  position: relative;
  color: var(--font-color);
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
  transition: color 0.5s ease;
}
@keyframes animloader {
  0% {
    box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
  }
  33% {
    box-shadow: -38px 0px, -14px -12px, 14px 0, 38px 0;
  }
  66% {
    box-shadow: -38px 0px, -14px 0, 14px -12px, 38px 0;
  }
  100% {
    box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
  }
}

@media screen and (max-width: 800px) {
  .blogs-list-container {
    margin: 0 0.5rem;
  }
}
